/*******************************************************
*
* Custom blog styles
* Written by bowtie.io (http://bowtie.io)
*
*******************************************************/

/*******************************************************/

/* Add/remove custom styles to this theme file */

/*******************************************************/

.blog #content h3 {font-weight:400 !important;}

.label-info {
background-color:#3b6a7b;
}
.label {
font-weight:normal; text-transform:uppercase;
}
.post-title, .post-title > a {
letter-spacing: 0.025em;
text-transform:uppercase !important;
}
.post-subtitle, .post-subtitle > a {
text-transform:uppercase;
margin: -5px 0 20px 0;
color:#333 !important;
font-weight: 300 !important;
font-family: "Lato","Ubuntu","Roboto",sans-serif;
letter-spacing: 0.025em;
font-size: 20px !important;
}
.post-body-short { margin: 25px 0 0px 0;font-size:medium;letter-spacing: 0.01rem;line-height: 1.5;font-family:'Noto-Serif',Georgia,Cambria,"Times New Roman",Times,serif;
}
.post-body { margin: 25px 0 60px 0;font-size:18px;letter-spacing: 0.01rem;line-height: 1.5;font-family:'Noto-Serif',Georgia,Cambria,"Times New Roman",Times,serif;
}
.post-body p { color:rgba(0, 0, 0, 0.8);font-size:18px;
}
.post-summary.first { margin-top: 30px !important;
}
.post-metadata {
font-weight:400; color:#333;margin-top:30px;display:inline-block;
}
.post-list{
	margin-top:-60px;
	.post-title {
		font-size:26px !important;
		margin-bottom: 10px;
		font-weight:600 !important;
		}
	h3 {
		margin:.5em 0 .5em !important;
		}
	a, a:visited {
			color: #426977 !important;
	}
	a.btn, a.btn:visited {
			color: #fff !important;
	}
	a.btn:hover, a.btn:active {
			color: #000 !important;
	}
}
#masthead .post-metadata {
margin-top:6px;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
		background-color: #000;
		border-color: #778899;
		color: #FFFFFF;
		cursor: default;
		z-index: 2;
}
.pagination > li > a:hover, .pagination > li > span{    background-color: #778899;
		border-color: #778899; color: #FFFFFF;
}
#masthead h1{
font-weight:600 !important; font-size:3em;text-align:left;
}
#masthead h3{
	text-align:left; font-size:1.5em;margin-top:0px;font-weight:400 !important;
}
.section.black p, .section.black a {
	color:#FFF !important;
}
.section.black #masthead h3 {
	color:#DDD !important;
}


.category-group {
	margin:0 0 40px 0;
	ul {
		list-style:none;
		margin-left: 5px !important;
		}
	li {
		font-size:1.25em;
		margin-bottom:10px;
		}
	h3 {
		margin:.5em 0 .5em !important;
		text-decoration:underline;
		}
	}

.breadcrumb {
	background-color: #F7F7F7;
}

#masthead .post-avatar {
	margin:0 10px 0 0 !important;
	float: left;
	height: 30px;
		width: 30px;
}
.post-summary {
		margin: 90px 0;
		.post-avatar {
		background-color: #F2F2F0;
		/*border: 1px solid #ccc;*/
		border-radius: 100%;
		float: right !important;
		height: 44px;
		margin-left: 16px;
		overflow: hidden;
		width: 44px;
	}
}
.post{
	img.quarter{
		width:25%;
	}
	img.third{
		width:33%;
	}
	img.half{
		width:50%;
	}
	img.threequarter{
		width:75%;
	}
	.left-buffer{
		margin-left:40px !important;
	}
	.right-buffer{
		margin-right:40px !important;
	}
}
